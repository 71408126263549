import logo from './images/nbd.svg';
import atc from './images/atthecontrol.svg'
import nick from './images/nick.jpg'
import chev from './images/chev.svg';
import printinglair from './images/printinglair.svg';
import bunnies from './images/monford_bunnies.jpg'
import './style/App.css';

function App() {
  return (
    <div className="App">
      <div class="landing">
        <div class="landing_container">
          <img id="logo" src={logo} />
          <p>About | Work | Contact | The Printing Lair</p>
        </div>
      </div>

      <div class="about">
          <div id="profile_container">
            <div id="profile_pic">
                <div id="profile_pic_container"><img src={nick} /></div>
            </div>
          </div>
          <div id="about_container">
            <h1>About Me</h1>
            <p>
              Hi I'm Nick, a full stack web developer with a passion for creating innovative digital solutions.
              My journey in web development began in high school as a member of a robotics team and their marketing divison, where I found a passion
              in creating web experinces. Since then, I have honed my skills and become an expert in various web technologies
              including HTML, CSS, JavaScript, Node.js, and PHP.
            </p>
            <p>
              As a developer, I bring a unique blend of technical proficiency and creative vision to every
              project. During my recovery from leukemia, I ventured into a new hobby of screen printing posters.
              This new passion allows me to combine my technical skills with my artistic side, adding another
              dimension to my abilities as a developer.
            </p>
            <p>
              I am a lifelong learner with a drive to always improve and take on new challenges. With my technical
               expertise, creative mindset, and determination, I am a valuable asset to any web development project.
               Whether I am coding or creating a new screen print, I approach every task with a commitment to
              excellence and a passion for what I do.
            </p>
              <div class="degree_container">

                <div class="degree">
                  <div id="title">Information Technology - Web Software Developer</div>
                  <div id="type">Associates of Applied Science</div>
                  <div id="school_date">Gateway Technical College <img id="chev" src={chev} /> 2021</div>
                </div>
                <div class="degree">
                  <div id="title">Information Technology - Web Programmer</div>
                  <div id="type">Technical Diploma</div>
                  <div id="school_date">Gateway Technical College <img id="chev" src={chev} /> 2021</div>
                </div>

              </div>
          </div>
      </div>

      <div id="work" class="work">
        <div id="left">
          <h1>Work</h1>
          <p><a href="#work">At The Control</a> | <a href="#work">Shelter Snap</a></p>
          <p><a href="#work">WCHS</a> | <a href="#work">OCHS</a></p>
          <p><a href="#work">Charger Robotics</a></p>
        </div>

        <div id="right">

          <div id="atc">
            <img src={atc} />
            <a href="#" id="seemore">See More</a>
          </div>

          <div id="shelter_snap">
            <img src={atc} />
          </div>

          <div id="wchs">
            <img src={atc} />
          </div>

          <div id="ochs">
            <img src={atc} />
          </div>

          <div id="charger_robotics">
            <img src={atc} />
          </div>

        </div>
      </div>

      <div id="printinglair" class="printinglair">
        <div id="right">

          <ul>
            <li>
              <img width="100px" src={bunnies} />
            </li>
            <li></li>
            <li></li>

            <li></li>
            <li></li>
            <li></li>
          </ul>


        </div>
        <div id="left">

          <img src={printinglair} />
          <p>The Printing Lair, while a moniker for my hobby is my new side business for custom,
          screen-printed posters, apparel, business cards and stickers! Here is some of my work,
          for more information about The Printing Lair please visit the official website here.</p>
        </div>
      </div>

      <div class="footer">
        <div class="footer_container">
          <h1>Contact</h1>
          <p id="cpy">&copy; Copyright Nick Block Designs, 2023 </p>
        </div>
      </div>

    </div>
  );
}

export default App;
